import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { children, childWidths, inputError, primaryStyledButton, text, x, y } from 'design/Styled'

interface Props {
  max?: number
}

export const Container = styled.div`
  ${text};
  ${y};

  .ui.dropdown,
  .ui.fluid.dropdown,
  .ui.input,
  .ui.fluid.input,
  input {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em !important;
    min-height: 36px;
  }

  .ui.input.labeled.right {
    & > input,
    &.fluid > input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .ui.dropdown {
    padding: 0 !important;
    &.error>.default.text{
    color:#ef705f;
    }
    &.error{
    border-color:#ef705f !important;
    }
  }

  .dropdown > .text,
  input {
    padding: 0.6rem 2.1rem 0.6rem 1rem !important;
  }

  .ui.search.dropdown > .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  .rp-transition-wrapper {
    height: 100%;
  }

  .rp-scrollable-child {
    min-height: 100%;
  }
`

export const Header = styled.div`
  ${y};
  &:after {
    display: block;
    position: relative;
    content: '';
    width: 80%;
    height: 1px;
    background: ${Colors.EmphasisLine};
    margin: 1.3em auto;
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  max-width: ${(props: Props) => props.max || 300}px;
  margin: 1em auto;
`

export const Row = styled.div`
  ${x};
  ${children};
  ${childWidths};
  margin-bottom: 1em;
`

export const Col = styled.div`
  ${y};
`

export const Section = styled.div`
  ${y};
`

export const Label = styled.label`
  font: ${fonts.small.bold};
  width: 100%;
  margin-bottom: 0.5em;
`

export const Submit = styled(Button)`
  ${primaryStyledButton};
`

export const Fa = styled(FontAwesomeIcon)``

export const StyledForm = styled(Form)`
  ${x};
  ${children};
`

export const StyledField = styled(Form.Field)``

export const StyledInput = styled(Input)`
  width: 100%;
  &.ui.input {
    align-items: stretch;
    input {
    .error & {
       ${inputError}
    }
    }
  }
  .ui.button {
    min-width: 0;
  }
  &.error.zipcode{
    input{
      ${inputError}
    }
  }
`

export const StyledDescription = styled.div`
    padding: 20px 0;
    line-height: 1.4;
    text-align: justify;
    font-size: 13px;
    h5{
      margin-bottom:5px;
    }
    ul{
      margin:5px 0;
    }
`
export const BtnWrapper = styled.div`
    margin-top: 20px;
`
export const NotificationContent = styled.div`
    padding: 20px;
     line-height: 1.4;
    text-align: center;
`
export const NotificationWrapper = styled.div`
    padding: 20px;
`
export const NotificationFooter = styled.footer`
    text-align: center;
`

export const StyledDropdown = styled(Dropdown)``
